import { Injectable } from '@angular/core';
import { SwUpdate, type VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
    constructor(swUpdate: SwUpdate) {
        swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(() => {
            this.reloadPage();
        });
    }

    /**
     * Reloads the current page by refreshing the document location.
     * This method forces the browser to reload the current document from the server.
     *
     * Seperate function so we can spy on it in tests.
     */
    /* istanbul ignore next */
    reloadPage() {
        document.location.reload();
    }
}
