import { AuthGuard, customClaims, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { type Routes } from '@angular/router';
import { map, pipe } from 'rxjs';
import { adminHashProtectionGuard, referralRerouteToInvoices } from './admin-hash/admin-hash-protection.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const adminGuard = () =>
    pipe(
        customClaims,
        map(claims => (Array.isArray(claims) ? false : claims['admin'] === true)),
    );
const adminOrReferralGuard = () =>
    pipe(
        customClaims,
        map(claims => (Array.isArray(claims) ? false : claims['admin'] === true || claims['referralCode'] != null)),
    );

export const routes: Routes = [
    /////////////////////////////////////////////
    // Unguarded routes
    /////////////////////////////////////////////
    { path: 'login', loadComponent: () => import('./login/login.component').then(m => m.LoginComponent) },
    { path: 'signup', loadComponent: () => import('./signup/signup.component').then(m => m.SignupComponent) },
    { path: 'verify', loadComponent: () => import('./verify/verify.component').then(m => m.VerifyComponent) },
    {
        path: 'share/:hash',
        loadComponent: () => import('./patient-link-design/patient-link-design.component').then(m => m.PatientLinkDesignComponent),
    },
    /////////////////////////////////////////////
    // Guarded routes
    /////////////////////////////////////////////
    {
        path: '',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        children: [
            { path: '', redirectTo: 'orders', pathMatch: 'full' },
            {
                path: 'patients',
                loadComponent: () => import('./patients/patients.component').then(m => m.PatientsComponent),
            },
            {
                path: 'patients/:id',
                loadComponent: () => import('./patient/patient.component').then(m => m.PatientComponent),
            },
            { path: 'new', loadComponent: () => import('./create-order/create-order.component').then(m => m.CreateOrderComponent) },
            {
                path: 'orders',
                canActivate: [referralRerouteToInvoices],
                loadComponent: () => import('./orders/orders.component').then(m => m.OrdersComponent),
            },
            { path: 'orders/:id', loadComponent: () => import('./orders/order/order.component').then(m => m.OrderComponent) },
            {
                path: 'orders/:id/draft',
                loadComponent: () => import('./create-order/create-order.component').then(m => m.CreateOrderComponent),
            },
            { path: 'profile', loadComponent: () => import('./profile/profile.component').then(m => m.ProfileComponent) },

            {
                path: 'invoices',
                loadComponent: () => import('./invoices/invoices.component').then(m => m.InvoicesComponent),
            },
            {
                path: 'invoices/:id',
                loadComponent: () => import('./invoices/invoice/invoice.component').then(m => m.InvoiceComponent),
            },

            /////////////////////////////////////////////
            // From here on, only admin routes!
            /////////////////////////////////////////////
            {
                path: 'invoice-management',
                loadComponent: () => import('./invoices/invoices.component').then(m => m.InvoicesComponent),
                canActivate: [AuthGuard, adminHashProtectionGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'settings',
                loadComponent: () => import('./app-settings/app-settings.component').then(m => m.AppSettingsComponent),
                canActivate: [AuthGuard, adminHashProtectionGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers',
                loadComponent: () => import('./customers/customers.component').then(m => m.CustomersComponent),
                canActivate: [AuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid',
                loadComponent: () => import('./customers/customer/customer.component').then(m => m.CustomerComponent),
                canActivate: [AuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/orders/:id',
                loadComponent: () => import('./orders/order/order.component').then(m => m.OrderComponent),
                canActivate: [AuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/patients/:id',
                loadComponent: () => import('./patient/patient.component').then(m => m.PatientComponent),
                canActivate: [AuthGuard],
                data: { authGuardPipe: adminGuard },
            },
            {
                path: 'customers/:customerid/invoices/:id',
                loadComponent: () => import('./invoices/invoice/invoice.component').then(m => m.InvoiceComponent),
                canActivate: [AuthGuard],
                data: { authGuardPipe: adminOrReferralGuard },
            },
        ],
    },
    { path: '**', redirectTo: '' },
];
