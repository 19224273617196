import { Component } from '@angular/core';
import { PromptUpdateService } from './prompt-update.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    constructor(readonly _updateService: PromptUpdateService) {}
    title = 'smile-art';
}
